<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ safety.id ? 'Edit' : 'New' }} Occupational Safety and Health master<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">{{ labels['active'] }} <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to occupational Safety % Health list"
              @click="$router.push({name: 'admin.ocupational-safety.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.name'] }}</label>
                        <input
                          v-model="safety.name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.code'] }}</label>
                        <input
                          v-model="safety.code"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.order'] }}</label>
                        <input
                          v-model="safety.order"
                          type="number"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.parent'] }}</label>
                        <v-select
                          v-if="safeties.length !== undefined"
                          v-model="safety.parent"
                          label="name"
                          :options="safeties"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="form-label">
                        Visibility
                      </p>
                      <div class="mb-3 demo-inline-spacing">
                        <div class="form-check form-check-success">
                          <input
                            id="colorCheck1"
                            type="checkbox"
                            class="form-check-input"
                            checked=""
                            disabled
                          >
                          <label
                            class="form-check-label"
                            for="colorCheck1"
                          >{{ labels['content.admin'] }}</label>
                        </div>
                        <div class="form-check form-check-success">
                          <input
                            id="colorCheck2"
                            v-model="new_visibility.researcher"
                            type="checkbox"
                            class="form-check-input"
                            :checked="safety.visibility ? safety.visibility.findIndex(x => x.name === 'researcher') >= 0 : false"
                          >
                          <label
                            class="form-check-label"
                            for="colorCheck2"
                          >{{ labels['content.researcher'] }}</label>
                        </div>
                        <div class="form-check form-check-success">
                          <input
                            id="colorCheck3"
                            v-model="new_visibility.host"
                            type="checkbox"
                            class="form-check-input"
                            :checked="safety.visibility ? safety.visibility.findIndex(x => x.name === 'host') >= 0 : false"
                          >
                          <label
                            class="form-check-label"
                            for="colorCheck3"
                          >{{ labels['content.host_cae'] }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.description'] }}</label>
                        <quill-editor
                          v-model="safety.description"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row"> <!-- Mostrar sólo si se marca el checkbox del host -->
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.host_description_cae'] }}</label>
                        <quill-editor
                          v-model="safety.host_description"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col">
                      <div class="row row__list-of-images">
                        <div class="col-sm-9">
                          <h3>Icon</h3>
                          <div class="mb-1">
                            <label
                              for="formImage"
                              class="form-label"
                            >{{ labels['content.select_image'] }}</label>
                            <input
                              id="formFile"
                              :ref="`file-icon`"
                              class="form-control"
                              type="file"
                              @change="loadIcon"
                            >
                          </div>
                          <div class="mb-2">
                            <a
                              href=""
                              class="btn btn-sm btn-outline-success me-1"
                            >Upload image</a>
                            <a
                              class="btn btn-sm btn-flat-danger"
                              data-bs-toggle="collapse"
                              href="#collapseAddImage"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseAddImage"
                            >Cancel</a>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div v-if="blob_url || icon_path" class="card h-100">
                            <div
                              class="card-img-top"
                            >
                              <img
                                v-if="blob_url"
                                height="100%"
                                width="100%"
                                :src="blob_url"
                              >
                              <span
                                v-if="icon_path"
                                class="bigger-icon"
                                v-html="icon_path"
                              />
                            </div>
                            <div class="card-body">
                              <a
                                href=""
                                class="btn btn-icon rounded-circle btn-flat-danger bg-light-danger"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete image"
                              ><i data-feather="trash-2" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      safetyId: this.$route.params.id,
      sending: false,
      errors: '',
      icon_path: '',
      blob_url: '',
      file: null,
      new_visibility: {},
    }
  },
  computed: {
    ...mapGetters({
      safety: 'safeties/safety',
      safeties: 'safeties/safeties',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'masters.occupational_safety_and_health_master')
    if (this.safetyId) {
      await this.$store.dispatch('safeties/fetchSafety', this.safetyId)
      this.icon_path = this.safety.icon_url
      this.new_visibility.researcher = this.safety.visibility.findIndex(x => x.name === 'researcher') >= 0
      this.new_visibility.host = this.safety.visibility.findIndex(x => x.name === 'host') >= 0
    } else {
      await this.$store.dispatch('safeties/cleanSafety')
    }

    if (this.safeties.length === 0) {
      await this.$store.dispatch('safeties/fetch')
    }
  },
  methods: {
    async save() {
      this.sending = true

      try {
        this.safety.new_visibility = this.new_visibility
        if (this.safetyId) {
          await this.$store.dispatch('safeties/update', { id: this.safetyId, data: this.safety, icon: this.file })
        } else {
          await this.$store.dispatch('safeties/create', { data: this.safety, icon: this.file })
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.safety.id) {
        Vue.swal('It has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.ocupational-safety.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    loadIcon() {
      this.file = this.$refs['file-icon'].files[0]
      this.icon_path = ''
      this.blob_url = URL.createObjectURL(this.file)
    },
  },

}
</script>
